import type { DefaultQueryError } from '@ahm/api-wrappers-core';
import { useMutation, type UseMutationOptions } from '@tanstack/react-query';
import {
  type FacebookPageResponse,
  type FacebookPageRequestBody,
  postFacebookPageFn,
} from './ahafood/upset-page-fn';

export const usePostFacebookPage = (
  opts?: UseMutationOptions<
    FacebookPageResponse,
    DefaultQueryError,
    FacebookPageRequestBody
  >
) =>
  useMutation<FacebookPageResponse, DefaultQueryError, FacebookPageRequestBody>(
    {
      mutationFn: postFacebookPageFn,
      ...opts,
    }
  );
