'use client';
/**
 * https://javascript.plainenglish.io/the-right-way-to-detect-mobile-breakpoints-in-nextjs-301ccb1976bd
 */
import { useContext, createContext } from 'react';
import { useWindowSize } from './use-window-size';

export const IsSsrMobileContext = createContext(false);

export function useIsMobile() {
  const isSsrMobile = useContext(IsSsrMobileContext);
  const { width: windowWidth } = useWindowSize();
  const isBrowserMobile = Boolean(windowWidth) && windowWidth < 768;

  return isSsrMobile || isBrowserMobile;
}
