import type { AxiosError } from '@ahm/api-wrappers-core';
import {
  QueryClient,
  type DefaultOptions,
  type UseMutationOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';

export type ExtractFnReturnType<FnType extends () => any> = ReturnType<FnType>;

export type QueryConfig<QueryFnType extends () => any> = Omit<
  UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
  'queryKey' | 'queryFn' | 'select'
>;

export type MutationConfig<MutationFnType extends () => any> =
  UseMutationOptions<
    ExtractFnReturnType<MutationFnType>,
    AxiosError,
    Parameters<MutationFnType>[0]
  >;

// const onError = (e: unknown) => {
//   const errorCode = (e as DefaultQueryError).response?.data.code.toString();
//   switch (errorCode) {
//     case ErrorCode.NotAuthorized:
//     case ErrorCode.TokenNotFound:
//     case ErrorCode.RefreshTokenHasExpired:
//     case ErrorCode.WrongPasscode:
//     case ErrorCode.WrongOtp:
//     case ErrorCode.UserNotFound:
//     case String(ERROR_CODE_OPSTECH.RECORD_NOT_FOUND.code):
//       return;

//     default:
//       const errorMessage =
//         (e as DefaultQueryError).response?.data.description ||
//         (e as DefaultQueryError).response?.data.title ||
//         (e as Error).message;

//       toast.error(errorMessage);

//       console.log('onError', errorMessage);
//   }
// };

const staleTime = 5 * 60 * 1000; // 5 minutes

const queryConfig: DefaultOptions = {
  mutations: {
    // onError,
  },
  queries: {
    retry: false, // If set to a number, failed queries will retry until the failed query count reaches that number.
    retryOnMount: false, // If true, the query will re-fetch on mount if the cached data is stale.
    refetchOnWindowFocus: false, // If "always", the query will always re-fetch in the background on window focus.
    refetchOnReconnect: false, // Defaults to true . If true, the query will re-fetch on reconnect if the cached data is stale
    staleTime, // The time in milliseconds after data becomes stale.
    // onError,
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });
