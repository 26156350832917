import { type AxiosRequestConfig, httpClient } from '@ahm/api-wrappers-core';
import { apiRoutes } from '../api-routes';

export interface PageResponse {
  pages: Page[];
}

export interface Page {
  page_id: string;
  page_access_token: string;
  page_name: string;
  status: string;
  update_at: number;
  subscribed: boolean;
  store_id: number;
  login_type: string;
}

export const getPageFn = async (config: AxiosRequestConfig = {}) => {
  const response = await httpClient.get<PageResponse>(
    apiRoutes.ahafood.get_page,
    config
  );

  return response?.data;
};
