'use client';

import {
  QueryClientProvider as TanStackQueryClientProvider,
  type QueryClientProviderProps,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useState } from 'react';
import { queryClient } from './query-client';

export function QueryClientProvider({
  children,
}: Omit<QueryClientProviderProps, 'client'>) {
  const [queryClientState] = useState(() => queryClient);

  return (
    <TanStackQueryClientProvider client={queryClientState}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </TanStackQueryClientProvider>
  );
}
