import { type UseQueryOptions, useQuery } from '@tanstack/react-query';
import { type GetUserInfoResponse, getUserInfoFn } from './ahafood';

export const useGetUserInfo = (
  opts?: UseQueryOptions<GetUserInfoResponse, Error>
) => {
  return useQuery<GetUserInfoResponse, Error>({
    queryKey: ['userInfor'],
    queryFn: getUserInfoFn,
    ...opts,
  });
};
