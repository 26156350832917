'use client';

import { loadJS } from '@ahm/common-helpers';
import { useEffect, useRef } from 'react';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eruda: any;
  }
}

interface ErudaLoaderProps {
  enabled?: boolean;
  src?: string;
}

export function ErudaLoader({
  enabled = false,
  src = `//cdn.jsdelivr.net/npm/eruda`,
}: ErudaLoaderProps) {
  const isInitialized = useRef(false);

  useEffect(() => {
    if (typeof window === 'undefined') return; // Ensure this only runs on the client side

    const loadMobileConsole = () => {
      loadJS(src)
        .then(() => {
          if (!isInitialized.current) {
            if (!window.eruda?._isInit) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              window.eruda.init();
            }
            isInitialized.current = true;
          }
        })
        .catch((error) => {
          console.error('Failed to load eruda.', error);
        });
    };

    const destroy = () => {
      if (window.eruda?._isInit) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        window.eruda.destroy();
        console.log('eruda is destroyed.');
      }
    };

    if (enabled) {
      loadMobileConsole();
    }

    return () => destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  return null;
}
