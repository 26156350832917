import { publicHttpClient } from '@ahm/api-wrappers-core';
import { apiRoutes } from '../api-routes';

export interface AcquireMerchantRequestBody {
  phone_number: string;
  email: string;
  name: string;
  city_id: string;
  g_recaptcha_response: string;
}

export interface AcquireMerchantResponse {
  msg: string;
}

export const acquireMerchantFn = async (body: AcquireMerchantRequestBody) => {
  const response = await publicHttpClient.post<AcquireMerchantResponse>(
    apiRoutes.ahafood.acquire_merchant,
    body,
    {
      withCredentials: true,
    }
  );

  return response.data;
};
