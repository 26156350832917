'use client';

import { IsSsrMobileContext } from './use-is-mobile';

export interface IsSsrMobileProviderProps {
  children: React.ReactNode;
  isSsrMobile: boolean;
}

export function IsSsrMobileProvider({
  children,
  isSsrMobile,
}: IsSsrMobileProviderProps) {
  return (
    <IsSsrMobileContext.Provider value={isSsrMobile}>
      <>{children}</>
    </IsSsrMobileContext.Provider>
  );
}
