import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { type PageResponse, getPageFn } from './ahafood/get-page-fn';

export const useGetPages = (
  opts?: Omit<UseQueryOptions<PageResponse, Error>, 'queryKey'>
) => {
  return useQuery<PageResponse, Error>({
    queryKey: ['facebookPages'],
    queryFn: getPageFn,
    ...opts,
  });
};
