import { httpClient } from '@ahm/api-wrappers-core';
import type { PageInfo } from '@/providers/auth-provider';

export type GetFacebookPagesResponse = {
  data: {
    access_token: string;
    category: string;
    category_list: { id: string; name: string }[];
    name: string;
    id: string;
    tasks: string[];
  }[];
};

export const getFacebookPagesFn = async (sessionToken: string) => {
  const response = await httpClient.get<PageInfo>(
    `https://graph.facebook.com/v20.0/me/accounts?access_token=${sessionToken}`
  );

  return response?.data;
};
