import type { DefaultQueryError } from '@ahm/api-wrappers-core';
import { useMutation, type UseMutationOptions } from '@tanstack/react-query';
import {
  type FBLoginResponse,
  type FBLoginRequestBody,
  fbLoginFn,
} from './ahafood';

export const useFBLoginMerchant = (
  opts?: UseMutationOptions<
    FBLoginResponse,
    DefaultQueryError,
    FBLoginRequestBody
  >
) =>
  useMutation<FBLoginResponse, DefaultQueryError, FBLoginRequestBody>({
    mutationFn: fbLoginFn,
    ...opts,
  });
